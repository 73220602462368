import CropperModal from 'components/cropperModal'
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import React, { useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import './index.scss'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Row,
} from 'reactstrap'
// import { sendData } from 'utli/apiService'
import { dataUrlToFile } from 'utli/fileConverter'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import useQuery from 'hooks/useQuery'
import { updateData } from 'utli/apiService'
import Select from 'react-select'
// import { isConstructorDeclaration } from 'typescript'

function EditRecruitment(props) {
  const jobtypes = [
    {
      label: 'Remote',
      value: 'remote',
    },
    {
      label: 'On-Site',
      value: 'onsite',
    },
    {
      label: 'Hybrid',
      value: 'hybrid',
    },
  ]
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  const [initState, setInitState] = useState({
    submitLoading: false,
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
    jobdescription: '',
  })

  const {
    response: { data },
    loading,
    error,
  } = useQuery(`recruitments/${props.match.params.id}`)

  const converttoHtml = (value) => {
    if (typeof value === 'object') {
      const rawContentState = convertToRaw(value.getCurrentContent())
      const markup = draftToHtml(rawContentState)
      return markup
    } else {
      return value
    }
  }

  React.useEffect(() => {
    setInitState({
      ...initState,
      jobdescription: converttoHtml(data?.jobdescription),
    })
  }, []) // eslint-disable-line

  const getDraft = (sampleMarkup) => {
    const blocksFromHTML = convertFromHTML(sampleMarkup ? sampleMarkup : '')
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    return EditorState.createWithContent(state)
  }

  const submitData = async (values) => {
    setInitState({ ...initState, submitLoading: true })
    if (!initState.jobdescription) {
      NotificationManager.error('Description is required')
    } else {
      const formData = new FormData()
      formData.append('jobtitle', values.jobtitle)
      formData.append('jobtype', values.jobtype)
      formData.append('positions', values.positions)
      formData.append('jobdescription', initState.jobdescription)

      if (cropImage) {
        console.log(cropImage)
        const imageFile = await dataUrlToFile(cropImage)
        formData.append('image', imageFile)
      }

      // validate image
      // if (formData.get('image') == null) {
      //   setInitState({ ...initState, imageError: true })
      //   return
      // } else if (formData.get('image') != null) {
      //   setInitState({ ...initState, imageError: false })
      // }

      console.log(formData.get('jobtitle'))
      console.log(formData.get('jobtype'))
      console.log(formData.get('positions'))
      console.log(formData.get('jobdescription'))

      const response = await updateData(
        `recruitments/${props.match.params.id}`,
        formData
      )

      if (response) {
        NotificationManager.success('Saved')
        window.location = '/recruitments'
        reset()
        setCropImage(null)
      }
    }
    setInitState({ ...initState, croppedImage: null, submitLoading: false })
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Update Recruitment</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <Row>
                  <Col md={6} sm={12}>
                    <FormGroup className="submit-form">
                      <label htmlFor="jobTitleInput">Job Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="jobTitleInput"
                        defaultValue={data?.jobtitle}
                        {...register('jobtitle', {
                          required: 'Job Title is required',
                        })}
                      ></input>
                    </FormGroup>
                    {errors.jobtitle && (
                      <span className="text-danger">
                        {errors?.jobtitle?.message}
                      </span>
                    )}
                  </Col>

                  <Col md={6} sm={12}>
                    <FormGroup className="submit-form">
                      <label htmlFor="jobTypeInput">Job Type</label>
                      <Controller
                        control={control}
                        name="jobtype"
                        className="jobtypeInput"
                        defaultValue={data?.jobtype}
                        rules={{ required: true }}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            inputRef={ref}
                            classNamePrefix="addl-class"
                            isClearable
                            isSearchable={false}
                            options={jobtypes}
                            defaultValue={data?.jobtype}
                            value={jobtypes.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                          />
                        )}
                      />
                      {/* <input
                        type="text"
                        className="form-control"
                        id="blogSubTitleInput"
                        defaultValue={data?.jobtype}
                        {...register('subtitle', {
                          required: 'Blog Subtitle is required',
                        })}
                      ></input> */}
                    </FormGroup>
                    {errors.subtitle && (
                      <span className="text-danger">
                        {errors?.jobtype?.message}
                      </span>
                    )}
                  </Col>

                  <Col md={6} sm={12}>
                    <FormGroup className="submit-form">
                      <label htmlFor="jobPositionInput">Job Position</label>
                      <input
                        type="text"
                        className="form-control"
                        id="jobPositionInput"
                        defaultValue={data?.positions}
                        {...register('positions', {
                          required: 'Job Positions is required',
                        })}
                      ></input>
                    </FormGroup>
                    {errors.jobtitle && (
                      <span className="text-danger">
                        {errors?.positions?.message}
                      </span>
                    )}
                  </Col>

                  <Col sm="12" className="mt-3 editor-main">
                    <label>Description</label>
                    <Editor
                      toolbarClassName="editor-toolbar"
                      wrapperClassName="editor-wrapper"
                      editorClassName="editor"
                      defaultEditorState={getDraft(data?.jobdescription)}
                      toolbar={{
                        options: [
                          'inline',
                          'blockType',
                          'fontSize',
                          'fontFamily',
                          'list',
                          'textAlign',
                          'colorPicker',
                          'link',
                          'embedded',
                          'emoji',
                          'remove',
                          'history',
                        ],
                      }}
                      name="coordination"
                      onEditorStateChange={(editorState) => {
                        const rawContentState = convertToRaw(
                          editorState.getCurrentContent()
                        )
                        const markup = draftToHtml(rawContentState)
                        setInitState({ ...initState, jobdescription: markup })
                      }}
                    />
                  </Col>
                </Row>
                <FormGroup className="mt-3">
                  <label>Image</label>

                  {cropImage ? (
                    <div>
                      <img
                        alt="placeholder containeer"
                        src={cropImage}
                        width="400"
                        height="400"
                        onClick={() => {
                          inputFile.current.click()
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        alt="category result"
                        src={data?.image}
                        width="400"
                        height="400"
                        onClick={() => {
                          inputFile.current.click()
                        }}
                      />
                    </div>
                  )}
                  {initState.imageError && (
                    <p className="text-danger">Image is required</p>
                  )}
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        setInitState({
                          ...initState,
                          openCropper: true,
                          image: URL.createObjectURL(event.target.files[0]),
                        })
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Save
                  </Button>
                </FormGroup>
              </Form>
              {initState.openCropper && (
                <CropperModal
                  isShow={initState.openCropper}
                  image={initState.image}
                  close={() =>
                    setInitState({ ...initState, openCropper: false })
                  }
                  setCroppedImage={async (image) => {
                    setCropImage(image)
                  }}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default EditRecruitment
