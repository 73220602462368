import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
// plugins styles from node_modules
import 'react-day-picker/lib/style.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-notifications/lib/notifications.css'
import 'select2/dist/css/select2.min.css'
import 'quill/dist/quill.core.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
// plugins styles downloaded
import 'assets/vendor/nucleo/css/nucleo.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.2.0'

import AdminLayout from 'layouts/Admin.js'
import AuthLayout from 'layouts/Auth.js'
// import Dashboard from "pages/Dashboard";

ReactDOM.render(
  <>
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/" render={(props) => <AdminLayout {...props} />} />
      </Switch>
    </BrowserRouter>
    <NotificationContainer />
  </>,
  document.getElementById('root')
)
