import CropperModal from 'components/cropperModal'
import { convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import './index.scss'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Row,
} from 'reactstrap'
import { sendData } from 'utli/apiService'
import { dataUrlToFile } from 'utli/fileConverter'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

function CreateNews() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  const [initState, setInitState] = useState({
    submitLoading: false,
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
    description: '',
  })

  const submitData = async (values) => {
    setInitState({ ...initState, submitLoading: true })
    if (!initState.description) {
      NotificationManager.error('Description is required')
    } else {
      const formData = new FormData()
      formData.append('title', values.title)
      formData.append('description', initState.description)

      if (cropImage) {
        const imageFile = await dataUrlToFile(cropImage)
        formData.append('image', imageFile)
      }
      // validate image
      if (formData.get('image') == null) {
        setInitState({ ...initState, imageError: true })
        return
      } else if (formData.get('image') != null) {
        setInitState({ ...initState, imageError: false })
      }

      const response = await sendData('newsandevents', formData)

      if (response) {
        NotificationManager.success('Saved')
        window.location = '/news'
        reset()
        setCropImage(null)
      }
    }

    setInitState({ ...initState, croppedImage: null, submitLoading: false })
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Create News</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <Row>
                  <Col md={6} sm={12}>
                    <FormGroup className="submit-form">
                      <label htmlFor="blogTitleInput"> Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="blogTitleInput"
                        {...register('title', {
                          required: 'Blog Title is required',
                        })}
                      ></input>
                    </FormGroup>
                    {errors.title && (
                      <span className="text-danger">
                        {errors?.title?.message}
                      </span>
                    )}
                  </Col>

                  <Col sm="12" className="mt-3 editor-main">
                    <label>Description</label>
                    <Editor
                      toolbarClassName="editor-toolbar"
                      wrapperClassName="editor-wrapper"
                      editorClassName="editor"
                      toolbar={{
                        options: [
                          'inline',
                          'blockType',
                          'fontSize',
                          'fontFamily',
                          'list',
                          'textAlign',
                          'colorPicker',
                          'link',
                          'embedded',
                          'emoji',
                          'remove',
                          'history',
                        ],
                      }}
                      name="coordination"
                      onEditorStateChange={(editorState) => {
                        const rawContentState = convertToRaw(
                          editorState.getCurrentContent()
                        )
                        const markup = draftToHtml(rawContentState)
                        setInitState({ ...initState, description: markup })
                      }}
                    />
                  </Col>
                </Row>
                <FormGroup className="mt-3">
                  <label>Image</label>
                  {cropImage ? (
                    <div>
                      <img
                        alt="croped result"
                        src={cropImage}
                        width="400"
                        height="400"
                        onClick={() => {
                          inputFile.current.click()
                        }}
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        inputFile.current.click()
                      }}
                      style={{
                        width: 400,
                        height: 400,
                        background: 'grey',
                        cursor: 'pointer',
                      }}
                    ></div>
                  )}
                  {initState.imageError && (
                    <p className="text-danger">Image is required</p>
                  )}
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        setInitState({
                          ...initState,
                          openCropper: true,
                          image: URL.createObjectURL(event.target.files[0]),
                        })
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Add news
                  </Button>
                </FormGroup>
              </Form>
              {initState.openCropper && (
                <CropperModal
                  isShow={initState.openCropper}
                  image={initState.image}
                  close={() =>
                    setInitState({ ...initState, openCropper: false })
                  }
                  setCroppedImage={async (image) => {
                    setCropImage(image)
                  }}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default CreateNews
