import CropperModal from 'components/cropperModal'
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import './index.scss'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Row,
} from 'reactstrap'
import { dataUrlToFile } from 'utli/fileConverter'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import useQuery from 'hooks/useQuery'
import { updateData } from 'utli/apiService'

function EditBlog(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  const [initState, setInitState] = useState({
    submitLoading: false,
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
    description: '',
  })

  const {
    response: { data },
    loading,
    error,
  } = useQuery(`blogs/${props.match.params.id}`)

  const converttoHtml = (value) => {
    if (typeof value === 'object') {
      const rawContentState = convertToRaw(value.getCurrentContent())
      const markup = draftToHtml(rawContentState)
      return markup
    } else {
      return value
    }
  }

  React.useEffect(() => {
    setInitState({
      ...initState,
      description: converttoHtml(data?.description),
    })
  }, []) // eslint-disable-line

  const getDraft = (sampleMarkup) => {
    const blocksFromHTML = convertFromHTML(sampleMarkup ? sampleMarkup : '')
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    return EditorState.createWithContent(state)
  }

  const submitData = async (values) => {
    setInitState({ ...initState, submitLoading: true })
    if (!initState.description) {
      NotificationManager.error('Description is required')
    } else {
      const formData = new FormData()
      formData.append('title', values.title)
      formData.append('subtitle', values.subtitle)
      formData.append('description', initState.description)

      if (cropImage) {
        const imageFile = await dataUrlToFile(cropImage)
        formData.append('image', imageFile)
      }

      const response = await updateData(
        `blogs/${props.match.params.id}`,
        formData
      )

      if (response) {
        NotificationManager.success('Saved')
        window.location = '/blogs'
        reset()
        setCropImage(null)
      }
    }

    setInitState({ ...initState, croppedImage: null, submitLoading: false })
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Update Blog</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <Row>
                  <Col md={6} sm={12}>
                    <FormGroup className="submit-form">
                      <label htmlFor="blogTitleInput">Blog Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="blogTitleInput"
                        defaultValue={data?.title}
                        {...register('title', {
                          required: 'Blog Title is required',
                        })}
                      ></input>
                    </FormGroup>
                    {errors.title && (
                      <span className="text-danger">
                        {errors?.title?.message}
                      </span>
                    )}
                  </Col>

                  <Col md={6} sm={12}>
                    <FormGroup className="submit-form">
                      <label htmlFor="blogSubTitleInput">Blog Subtitle</label>
                      <input
                        type="text"
                        className="form-control"
                        id="blogSubTitleInput"
                        defaultValue={data?.subtitle}
                        {...register('subtitle', {
                          required: 'Blog Subtitle is required',
                        })}
                      ></input>
                    </FormGroup>
                    {errors.subtitle && (
                      <span className="text-danger">
                        {errors?.subtitle?.message}
                      </span>
                    )}
                  </Col>

                  <Col sm="12" className="mt-3 editor-main">
                    <label>Description</label>
                    <Editor
                      toolbarClassName="editor-toolbar"
                      wrapperClassName="editor-wrapper"
                      editorClassName="editor"
                      toolbar={{
                        options: [
                          'inline',
                          'blockType',
                          'fontSize',
                          'fontFamily',
                          'list',
                          'textAlign',
                          'colorPicker',
                          'link',
                          'embedded',
                          'emoji',
                          'remove',
                          'history',
                        ],
                      }}
                      defaultEditorState={getDraft(data?.description)}
                      name="coordination"
                      onEditorStateChange={(editorState) => {
                        const rawContentState = convertToRaw(
                          editorState.getCurrentContent()
                        )

                        const markup = draftToHtml(rawContentState)
                        setInitState({ ...initState, description: markup })
                      }}
                    />
                    {/* <Editor
                      toolbarClassName="editor-toolbar"
                      wrapperClassName="editor-wrapper"
                      editorClassName="editor"
                      defaultEditorState={getDraft(data?.description)}
                      toolbar={{ options: ['list'] }}
                      name="coordination"
                      onEditorStateChange={(editorState) => {
                        const rawContentState = convertToRaw(
                          editorState.getCurrentContent()
                        )
                        const markup = draftToHtml(rawContentState)
                        setInitState({ ...initState, description: markup })
                      }}
                    /> */}
                  </Col>
                </Row>
                <FormGroup className="mt-3">
                  <label>Image</label>

                  {cropImage ? (
                    <div>
                      <img
                        alt="placeholder containeer"
                        src={cropImage}
                        width="400"
                        height="400"
                        onClick={() => {
                          inputFile.current.click()
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        alt="category result"
                        src={data?.image}
                        width="400"
                        height="400"
                        onClick={() => {
                          inputFile.current.click()
                        }}
                      />
                    </div>
                  )}
                  {initState.imageError && (
                    <p className="text-danger">Image is required</p>
                  )}
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        setInitState({
                          ...initState,
                          openCropper: true,
                          image: URL.createObjectURL(event.target.files[0]),
                        })
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Save
                  </Button>
                </FormGroup>
              </Form>
              {initState.openCropper && (
                <CropperModal
                  isShow={initState.openCropper}
                  image={initState.image}
                  close={() =>
                    setInitState({ ...initState, openCropper: false })
                  }
                  setCroppedImage={async (image) => {
                    setCropImage(image)
                  }}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default EditBlog
