import Blog from 'pages/blogs'
import News from 'pages/news&event/index'
import CreateBlog from 'pages/blogs/create'
import EditBlog from 'pages/blogs/edit'
import Home from 'pages/Home'
import Login from 'pages/Login'
import Recruitments from 'pages/recruitment'
import CreateRecruitment from 'pages/recruitment/create'
import EditRecruitment from 'pages/recruitment/edit'
import CreateNews from 'pages/news&event/create'
import EditNews from 'pages/news&event/edit'

const routes = [
  {
    path: '/login',
    name: 'login',
    icon: 'fas fa-home text-primary',
    component: Login,
    layout: '/auth',
    invisible: true,
  },

  {
    path: '',
    name: 'Home',
    icon: 'fas fa-home text-default',
    component: Home,
    layout: '/',
  },

  {
    collapse: true,
    name: 'Our Blogs',
    icon: 'fas fa-user-friends text-default',
    state: 'blogsCollapse',
    views: [
      {
        path: 'blogs',
        name: 'List',
        icon: 'fas fa-list',
        miniName: 'A',
        component: Blog,
        layout: '/',
      },
      {
        path: 'blogs/create',
        name: 'Create',
        icon: 'fas fa-plus-circle',
        miniName: 'C',
        component: CreateBlog,
        layout: '/',
      },
      {
        path: 'blogs/edit/:id',
        name: 'Edit',
        icon: 'fas fa-plus-circle',
        miniName: 'Update',
        component: EditBlog,
        layout: '/',
        invisible:true
      },
    ],
  },

  {
    collapse: true,
    name: 'Our Recruitments',
    icon: 'fas fa-user-friends text-default',
    state: 'recruitmentsCollapse',
    views: [
      {
        path: 'recruitments',
        name: 'List',
        icon: 'fas fa-list',
        miniName: 'A',
        component: Recruitments,
        layout: '/',
      },
      {
        path: 'recruitments/create',
        name: 'Create',
        icon: 'fas fa-plus-circle',
        miniName: 'C',
        component: CreateRecruitment,
        layout: '/',
      },
      {
        path: 'recruitments/edit/:id',
        name: 'Edit',
        icon: 'fas fa-plus-circle',
        miniName: 'Update',
        component: EditRecruitment,
        layout: '/',
        invisible:true
      },
    ],
  },

  {
    collapse: true,
    name: 'Our News and Event',
    icon: 'fas fa-user-friends text-default',
    state: 'newsCollapse',
    views: [
      {
        path: 'news',
        name: 'List',
        icon: 'fas fa-list ',
        miniName: 'A',
        component: News,
        layout: '/',
      },
      {
        path: 'news/create',
        name: 'Create',
        icon: 'fas fa-plus-circle',
        miniName: 'C',
        component: CreateNews,
        layout: '/',
      },
      {
        path: 'news/edit/:id',
        name: 'Edit',
        icon: 'fas fa-plus-circle ',
        miniName: 'Update',
        component: EditNews,
        layout: '/',
        invisible:true
      },
    ],
  },
  
]

export default routes
