import axios from 'axios'
import { getCache } from 'lib/cache'
import { NotificationManager } from 'react-notifications'

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
const headers = {
  'Content-Type': 'multipart/form-data',
  Accept: 'application/json',
}

let token = getCache('rcs_token')
if (token) {
  headers.Authorization = `Bearer ${token}`
}
axios.defaults.headers = headers
let response

export async function getData(url, params) {
  response = await axios.get(`${apiEndpoint}${url}`, { params: params })
  return response
}

export async function sendData(url, data) {
  try {
    response = await axios.post(`${apiEndpoint}${url}`, data)
  } catch (e) {
    NotificationManager.error(e.response.data.message)

    return false
  }
  return response
}

export async function updateData(url, data) {
  try {
    // laravel put method (?_method=PUT)
    response = await axios.post(`${apiEndpoint}${url}?_method=PUT`, data)
  } catch (e) {
    NotificationManager.error(e.response.data.message)

    return false
  }
  return response
}

export async function deleteData(url, data) {
  try {
    response = await axios.delete(`${apiEndpoint}${url}`, data)
  } catch (e) {
    NotificationManager.error(e.response.data.message)

    return false
  }
  return response
}
