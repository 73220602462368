import CropperModal from 'components/cropperModal'
import { convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import React, { useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import ReactDatetime from 'react-datetime'
import Select from 'react-select'
import './index.scss'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Row,
} from 'reactstrap'
import { sendData } from 'utli/apiService'
import { dataUrlToFile } from 'utli/fileConverter'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

function CreateRecruitment() {
  const jobtypes = [
    {
      label: 'Remote',
      value: 'remote',
    },
    {
      label: 'On-Site',
      value: 'onsite',
    },
    {
      label: 'Hybrid',
      value: 'hybrid',
    },
  ]
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  const [initState, setInitState] = useState({
    submitLoading: false,
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
    jobdescription: '',
    deadline: null,
  })

  const submitData = async (values) => {
    setInitState({ ...initState, submitLoading: true })
    if (!initState.deadline) {
      NotificationManager.error('Deadline is required')
    } else if (!initState.jobdescription) {
      NotificationManager.error('Description is required')
    } else {
      const formData = new FormData()
      formData.append('jobtitle', values.jobtitle)
      formData.append('jobtype', values.jobtype)
      formData.append('positions', values.positions)
      formData.append('jobdescription', initState.jobdescription)
      formData.append('deadline', initState.deadline)

      if (cropImage) {
        const imageFile = await dataUrlToFile(cropImage)
        formData.append('image', imageFile)
      }

      // validate image
      if (formData.get('image') == null) {
        setInitState({ ...initState, imageError: true })
        return
      } else if (formData.get('image') != null) {
        setInitState({ ...initState, imageError: false })
      }

      const response = await sendData('recruitments', formData)

      if (response) {
        NotificationManager.success('Saved')
        window.location = '/recruitments'
        reset()
        setCropImage(null)
      }
    }
    setInitState({ ...initState, croppedImage: null, submitLoading: false })
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Create Recruitment</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <Row>
                  <Col md={6} sm={12}>
                    <FormGroup className="submit-form">
                      <label htmlFor="jobTitleInput">Job Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="jobTitleInput"
                        {...register('jobtitle', {
                          required: 'Job Title is required',
                        })}
                      ></input>
                    </FormGroup>
                    {errors.jobtitle && (
                      <span className="text-danger">
                        {errors?.jobtitle?.message}
                      </span>
                    )}
                  </Col>

                  <Col md={6} sm={12}>
                    <FormGroup className="submit-form">
                      <label htmlFor="jobTypeInput">Job Type</label>
                      <Controller
                        control={control}
                        name="jobtype"
                        className="jobtypeInput"
                        rules={{ required: true }}
                        render={({ field: { onChange, value, ref } }) => (
                          // updated validate with isClearable and isSearchable
                          <Select
                            inputRef={ref}
                            isClearable
                            isSearchable={false}
                            classNamePrefix="addl-class"
                            options={jobtypes}
                            value={jobtypes.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                          />
                        )}
                      />
                    </FormGroup>
                    {errors.jobtype && (
                      <span className="text-danger">
                        {/* {errors?.jobtype?.message} */}
                        {'Job Type is required'}
                      </span>
                    )}
                  </Col>

                  <Col md={6} sm={12}>
                    <FormGroup className="submit-form">
                      <label htmlFor="positionsInput">Job Position</label>
                      <input
                        type="text"
                        className="form-control"
                        id="positionsInput"
                        {...register('positions', {
                          required: 'Positions is required',
                        })}
                      ></input>
                    </FormGroup>
                    {errors.positions && (
                      <span className="text-danger">
                        {errors?.positions?.message}
                      </span>
                    )}
                  </Col>

                  {/* Date Picker */}
                  <Col md={6} sm={12}>
                    <FormGroup className="submit-form">
                      <label htmlFor="deadlineInput">Job Deadline</label>
                      <ReactDatetime
                        name="deadline"
                        inputProps={{
                          placeholder: 'Date Picker Here',
                        }}
                        onChange={(e) =>
                          setInitState({
                            ...initState,
                            deadline: e.format('YYYY-MM-DD'),
                          })
                        }
                        timeFormat={false}
                        dateFormat={'DD-MM-YYYY'}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="12" className="mt-3 editor-main">
                    <label>Description</label>
                    <Editor
                      toolbarClassName="editor-toolbar"
                      wrapperClassName="editor-wrapper"
                      editorClassName="editor"
                      toolbar={{
                        options: [
                          'inline',
                          'blockType',
                          'fontSize',
                          'fontFamily',
                          'list',
                          'textAlign',
                          'colorPicker',
                          'link',
                          'embedded',
                          'emoji',
                          'remove',
                          'history',
                        ],
                      }}
                      name="coordination"
                      onEditorStateChange={(editorState) => {
                        const rawContentState = convertToRaw(
                          editorState.getCurrentContent()
                        )
                        const markup = draftToHtml(rawContentState)
                        setInitState({ ...initState, jobdescription: markup })
                      }}
                    />
                  </Col>
                </Row>
                <FormGroup className="mt-3">
                  <label>Image</label>
                  {cropImage ? (
                    <div>
                      <img
                        alt="croped result"
                        src={cropImage}
                        width="400"
                        height="400"
                        onClick={() => {
                          inputFile.current.click()
                        }}
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        inputFile.current.click()
                      }}
                      style={{
                        width: 400,
                        height: 400,
                        background: 'grey',
                        cursor: 'pointer',
                      }}
                    ></div>
                  )}
                  {initState.imageError && (
                    <p className="text-danger">Image is required</p>
                  )}
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        setInitState({
                          ...initState,
                          openCropper: true,
                          image: URL.createObjectURL(event.target.files[0]),
                        })
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Add new recruitment
                  </Button>
                </FormGroup>
              </Form>
              {initState.openCropper && (
                <CropperModal
                  isShow={initState.openCropper}
                  image={initState.image}
                  close={() =>
                    setInitState({ ...initState, openCropper: false })
                  }
                  setCroppedImage={async (image) => {
                    setCropImage(image)
                  }}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default CreateRecruitment
