import React from 'react'
import { useHistory } from 'react-router'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import queryString from 'query-string'
function CustomPagnation({ pageCount }) {
  const router = useHistory()
  const count = Math.ceil(pageCount)
  const query = queryString.parse(router.location.search)
  const pageChange = (i) => {
    query.page = i
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }
  return (
    <>
      <nav aria-label="Page navigation example">
        <Pagination>
          {query.page > 1 && (
            <PaginationItem>
              <PaginationLink
                aria-label="Previous"
                onClick={() => pageChange(query.page - 1)}
              >
                <i className="fa fa-angle-left" />
                <span className="sr-only">Previous</span>
              </PaginationLink>
            </PaginationItem>
          )}
          {Array(count)
            .fill(1)
            .map((el, i) => (
              <PaginationItem
                className={
                  '' + (i + 1 === parseInt(query.page || 1) ? 'active' : '')
                }
              >
                <PaginationLink onClick={(e) => pageChange(i + 1)}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}

          {query.page < pageCount && (
            <PaginationItem>
              <PaginationLink
                aria-label="Next"
                href="#pablo"
                onClick={() => pageChange(query.page + 1)}
              >
                <i className="fa fa-angle-right" />
                <span className="sr-only">Next</span>
              </PaginationLink>
            </PaginationItem>
          )}
        </Pagination>
      </nav>
    </>
  )
}

export default CustomPagnation
