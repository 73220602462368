import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap'
import useQuery from 'hooks/useQuery'
import { getData } from 'utli/apiService'
const Home = () => {
  const [dataLength, setDataLength] = useState({
    blogs: 0,
    recruitments: 0,
    newsandevents: 0,
  })
  let url = 'blogs?'
  const {
    // response,
    loading,
    error,
  } = useQuery(url)
  const blogsUrl = 'blogs'
  const recruitmentsUrl = 'recruitments'
  const newsandeventsUrl = 'newsandevents'

  // fetching all data and generate total length
  const fetchData = useCallback(async () => {
    const blogsLength = await getData(blogsUrl)
    const recruitmentsLength = await getData(recruitmentsUrl)
    const newsandeventsLength = await getData(newsandeventsUrl)

    setDataLength({
      ...dataLength,
      blogs: blogsLength.data.data.length,
      recruitments: recruitmentsLength.data.data.length,
      newsandevents: newsandeventsLength.data.data.length,
    })
  }, []) //eslint-disable-line

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>

  return (
    <Row>
      <Col md="4" sm="6">
        <Card className="card-stats mb-4 pb-3 pt-3 mb-3">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  Today Blogs
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {dataLength.blogs}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md="4" sm="6">
        <Card className="card-stats mb-4 pb-3 pt-3 mb-3">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  Total Recruitments
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {dataLength.recruitments}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md="4" sm="6">
        <Card className="card-stats mb-4 pb-3 pt-3 mb-3">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  Total New and Events
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {dataLength.newsandevents}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {/* <Col md="4" sm="6">
        <Card className="card-stats mb-4 pb-3 pt-3 mb-3">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  New Users
                </CardTitle>
                <span className="h2 font-weight-bold mb-0"></span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md="4" sm="6">
        <Card className="card-stats mb-4 pb-3 pt-3 mb-3">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  Total Users
                </CardTitle>
                <span className="h2 font-weight-bold mb-0"></span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col> */}
    </Row>
  )
}

export default Home
