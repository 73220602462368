import CustomPagnation from 'components/pagination'
import React from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
  Table,
} from 'reactstrap'
import { paginationLimit } from 'variables/limits'
import useQuery from 'hooks/useQuery'
import { confirmAlert } from 'react-confirm-alert'
import { deleteData } from 'utli/apiService'

function Blog() {
  const router = useHistory();

  const headers = ['#', 'Title', 'Subtitle', 'Image', 'Actions'];

  const query = queryString.parse(router.location.search);

  let url = 'blogs?';
  let page = query.page || 1;

  const {
    response: { data },
    loading,
    error,
  } = useQuery(url);



  const deleteBLog = (id)=>{
    confirmAlert({
      title: 'Confirm to delete',
      message:'Are you sure to delete? Data cannot be recovered.',
      buttons:[
        {
          label: 'Yes',
          onClick: async()=>{
            const response = await deleteData(`blogs/${id}`);
            if(response){
              window.location.reload();
            }
          }
        },
        {
          label:'No',
          onClick:()=>{},
        }
      ]
    })
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Blogs</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  href={`${router.location.pathname}`}
                >
                  <span className="btn-inner--text">View All</span>
                </Button>

                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  onClick={(e) => router.push('blogs/create')}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">Add New</span>
                </Button>
              </Col>
            </Row>
          </CardTitle>

          <Table responsive bordered hover className="text-center">
            <thead className="thead-light">
              <tr>
                {headers.map((element, index) => {
                  return (
                    <th
                      className=" sort"
                      data-sort={element}
                      scope="col"
                      key={`user-account-new-header-${index}`}
                    >
                      {element}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((element, index) => {
                return (
                  <tr key={`blog-list-${element.id}`}>
                    <td>
                      {index + 1 + page * paginationLimit - paginationLimit}
                    </td>
                    <td>{element.title.length === 100? (element.title).substring(0,100)+'...':element.title}</td>

                    <td>{element.subtitle.length === 100? (element.subtitle).substring(0,100)+'...':element.subtitle}</td>

                    {/* <td>{element.description.length ===100?(element.description).substring(0,100)+'...': element.description}</td> */}

                    <td>
                      <img
                        src={element.image}
                        alt="profile"
                        width="80"
                        height="80"
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    </td>
                    <td>
                      <div>
                        <Button
                          size="sm"
                          className="btn-icon-only"
                          color="success"
                          onClick={() => {
                            router.push(`/blogs/edit/${element.id}`)
                          }}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-pen" />
                          </span>
                        </Button>
                        <Button
                          size="sm"
                          className="btn-icon-only"
                          color="danger"
                            onClick={() => deleteBLog(element.id)}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-trash" />
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </CardBody>
        {data.length > paginationLimit && (
        <CardFooter className="d-flex justify-content-end">
          <CustomPagnation
            pageCount={data.length / paginationLimit}
          ></CustomPagnation>
        </CardFooter>
        )}
      </Card>
    </>
  )
}

export default Blog
